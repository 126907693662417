import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const UpperNavBar = () => {
  return (
    <main style={{width: '100%', height: '100px', marginTop: '0', paddingTop: '0', paddingBottom: '0'}} className="text-secondary">
       <h3 style={{marginTop: '.5em', color:'#064CAC', fontWeight: 'bolder'}}>Get Any INSURANCE POLICY at one place.</h3>
       <hr/>
       
  
</main>
  
  )
}

export default UpperNavBar
